import { takeLatest, put, call, all } from "redux-saga/effects";
import * as actionTypes from "./Actions/ActionTypes";
import { flattenArray } from "../../utils/commonUtilities";
import { isEmpty } from "lodash";

function* generateExcelWorker(action) {
  const level_ln_map = {
    level1: "l1_name",
    level2: "l2_name",
    level3: "l3_name",
    level4: "l4_name",
    level5: "l5_name",
    level6: "l6_name",
    level7: "l7_name"
  };

  let PARALLEL_CALLS_COUNT = 10;

  try {
    const { payload, api } = action;
    const { total, parallelCount, rowIndexKey, rowCountKey, row_index, row_count } = payload;
    const row_index_key = rowIndexKey || "row_index";
    const row_count_key = rowCountKey || "row_count";
    
    if(payload.rowIndexKey) {
        delete payload.rowIndexKey;
    }
    if(payload.rowCountKey) {
        delete payload.rowCountKey;
    }

    if(parallelCount) {
      PARALLEL_CALLS_COUNT = parallelCount;
      delete payload.parallelCount;
    }
    delete payload.total;
    const req = {};

    for (const key in payload) {
      // if (payload[key]) {
      const reqKey = level_ln_map[key] ? level_ln_map[key] : key;
      if(payload.hasOwnProperty(key)) {
        (req[reqKey] = payload[key]);
      } 
      // }
    }
    req[row_index_key] = row_index
    req[row_count_key] = row_count

    const parallelCalls = [];
    let rowIndex = payload?.row_index
    let count = 1;
    let run = true;
    while (run) {
      if (count >= PARALLEL_CALLS_COUNT) {
        run = false;
      }
      if ((rowIndex < total) || !total) {
        parallelCalls.push(call(api, {...req, [row_index_key]: rowIndex }))
      }
      rowIndex = payload.row_index + (count * payload.row_count);
      count += 1;
    }
    const res = yield all(parallelCalls);
    // const res = yield call(getExcessTableData, req);
    if (res[0]?.data?.status) {
      let overallData = [];
      const { out_of_data, styleIndex } = res[res?.length - 1]?.data;
      const isStyleIndex = req.hasOwnProperty("styleIndex");
      let nextStyleIndex;
      let isPush = true;
      res?.forEach((item) => {
        if(isPush) {
          overallData = [...overallData, ...item?.data?.data];
        }
        if(isStyleIndex && (payload["styleIndex"] !== item?.data?.styleIndex)) {
          isPush = false;
          nextStyleIndex = item?.data?.styleIndex;
        }
      });
      const count = res[0].data?.total_count;
      const dataObj = {
        type: actionTypes.GET_EXCEL_DATA_SUCCESS,
        excelData: overallData,
        nextIndex: payload.row_index + (payload.row_count * PARALLEL_CALLS_COUNT)
      };
      if (count) {
        dataObj["totalCountExcel"] = count;
        dataObj["totalCount"] = count;
      }
      else if (res[0]?.data?.hasOwnProperty("out_of_data")) {
        dataObj["out_of_data"] = out_of_data;
        dataObj["styleIndex"] = nextStyleIndex || styleIndex;
        if(payload["styleIndex"] !== dataObj["styleIndex"]) {
          dataObj["nextIndex"] = 1000;
        }
      }
      yield put(dataObj);
    } else {
      yield put({ type: actionTypes.GET_EXCEL_DATA_ERROR, error: "Something went wrong." });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_EXCEL_DATA_ERROR, error: "Something went wrong." });
  }
}

function* generateExcelWatcher() {
  yield takeLatest(actionTypes.GET_EXCEL_DATA, generateExcelWorker);
}

export function* downloadExcelSaga() {
  yield all([generateExcelWatcher()]);
}
