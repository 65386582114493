import React, { useCallback, useEffect, useState } from "react";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import EditIconCell from '../../../components/Table/Cellrenderer/EditIconCell';
import EditProductAllocationModal from './EditProductAllocationModal'
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import ColumnFilter from '../../UserManagement/ColumnFilter'
import { numberUSFormatting, oneDecimal } from "../../../utils/formatters/valueFormatters";
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import { connect } from "react-redux";
import { getPackDetails, updatePacksEachesForMultipleStores } from "../FinalizeAction";
import EditPackDetails from "./EditPackDetails";
import { getNumberOfRecordsPerPages, isAshley, isSizeComponentForEdit, scrollIntoView } from "../../../utils/commonUtilities";
import InputCell from "../../../components/Table/Cellrenderer/InputCell";
import { cloneDeep, isEmpty } from "lodash";
import PageLoader from "../../../components/Loader/PageLoader";
import BulkEditPacksAndEaches from "./BulkEditPacksAndEaches";
import PacksEachesBulkEdit from "./PacksEachesBulkEdit";
import AvailableTable from "./AvailableTable";
import "../Finalize.css";
import Indicator from "../../../components/IndicatorComponent/Indicator";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ProductDetailsTable = (props) => {

    const {dcs} = props.productDetails 

    const [RTinstance, setRTinstance] = useState(null)
    const [isDisabled, setIsDisabled] = useState(true)
    const [showEditModal, setShowEditModal] = useState(false)
    const [editData, setEditData] = useState({})
    const [initialHiddenColumns, setInitialHiddenColumns] = useState([])
    // const [keyRT, setKeyRT] = useState(props.viewAllocationCall ? "sortAndSearch" : "productDetailView")
    const [keyRT, setKeyRT] = useState(null)
    const [packsAvailable, setPacksAvailable] = useState(0)
    const [eachesAvailable, setEachesAvailable] = useState({})
    const [eachesRequest, setEachesRequest] = useState({})
    const [packsRequest, setPacksRequest] = useState({})
    const [updatesEachesSizeMapping, setUpdatesEachesSizeMapping] = useState({})
    const [buttonLabel, setButtonLabel] = useState('')
    const [selectedRows, setSelectedRows] = useState([])
    const [availableColumns, setAvailableColumns] = useState([])
    const [availableData, setAvailableData] = useState([])
    const [showAvailable, setShowAvailable] = useState(false)
    const [showButtons, setShowButtons] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false)
    const [tableData, setTableData] = useState([])
    const [initSelectedRows, setInitialSelectedRows] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [pageIndex, setPageIndex] = useState(0)
    const [initIndex, setInitIndex] = useState(0)

    const { is_eaches, is_pack, pack_config, is_multiple_packs } = props.productDetails

    const pdtRef = React.useRef();

    useEffect(() => {
        setTableData(props.data)
    }, [props.data])

    useEffect(() => {
        setButtonLabel((is_eaches && is_pack) ? "" : (is_eaches ? 'Eaches' : 'Packs'))
    }, [props.productDetails, is_eaches])

    useEffect(() => {
        if (props.viewAllocationCall) {
            setShowButtons(false)
            setKeyRT("sortAndSearch")
            setInitialHiddenColumns(["actions", "allocated_reserve"]);
        }
        else if (!props.viewAllocationCall && (is_pack && is_eaches)) {
            setShowButtons(true)
            setKeyRT("productDetailView")
        }
        else if (!props.viewAllocationCall && (!is_pack && !is_eaches)) {
            setShowButtons(false)
            setKeyRT("sortAndSearch")
        }
        else if (!props.viewAllocationCall && (is_pack || is_eaches)) {
            setShowButtons(true)
            setKeyRT("productDetailView")
        }
    }, [is_pack, is_eaches, props.viewAllocationCall])

    useEffect(() => {
        props.isFinalized && setInitialHiddenColumns(["capacity", "capacity_category", "net_capacity_available", "store_inv", "actions", "allocated_reserve"]);
    }, [props.isFinalized])

    const hideColumnsIfFinalize = () => {
        if(props.isFinalized) {
            return [];
        }
        else {
            return [{
                Header: "Allocated Reserve",
                accessor: "allocated_reserve",
                Filter: NumberRangeColumnFilter,
                Cell: (instance) => cellRendererForRows(instance),
                filter: 'between'
            },
            {
                Header: "Capacity Category",
                accessor: "capacity_category",
                Cell: (instance) => cellRendererForRows(instance),
                Filter: ColumnFilter
            },
            {
                Header: "Store Inventory",
                accessor: "store_inv",
                Filter: NumberRangeColumnFilter,
                Cell: (instance) => cellRendererForRows(instance),
                filter: 'between'
            },
            {
                Header: "Store Capacity",
                accessor: "capacity",
                Filter: NumberRangeColumnFilter,
                Cell: (instance) => cellRendererForRows(instance),
                filter: 'between'
            },
            {
                Header: "Net Store Capacity Available",
                accessor: "net_capacity_available",
                Filter: (instance) => <NumberRangeColumnFilter {...instance} allowNegative />,
                Cell: (instance) => cellRendererForRows(instance),
                filter: 'between'
            }]
        }
    }

    const productDetailsDatacolumns = React.useMemo(() => [
        {
            Header: " ",
            sticky: "left",
            columns: [{
            Header: ' ',
            sticky: "left",
            columns: [
                {
                    Header: "Store Number",
                    accessor: 'store_code',
                    Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
                    filter: 'bulkFilterSpaceSeperated',
                    Cell: (instance) => cellRendererForRows(instance),
                },
                {
                    Header: "Store Name",
                    accessor: "store_name",
                    Filter: ColumnFilter,
                    Cell: (instance) => cellRendererForRows(instance),
                },
                {
                    Header: "Store Grade",
                    accessor: "store_grade",
                    Filter: ColumnFilter,
                    filter: 'exactText',
                    Cell: (instance) => cellRendererForRows(instance),
                },
                {
                    Header: "Climate",
                    accessor: "climate",
                    Filter: ColumnFilter,
                    filter: 'exactText',
                    Cell: (instance) => cellRendererForRows(instance),
                },
                {
                    Header: 'Country',
                    accessor: 'country',
                    Filter: ColumnFilter,
                    Cell: (instance) => cellRendererForRows(instance),
                }

            ]
        }]
        },
        {
            Header: "Original APS",
            accessor: row => row.original_aps ? oneDecimal(Number(row.original_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: 'Actual APS',
            accessor: row => row.forecast_aps ? oneDecimal(Number(row.forecast_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),

        },
        {

            Header: 'OH',
            accessor: 'oh',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),

        },
        {
            Header: 'OO',
            accessor: 'oo',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: 'IT',
            accessor: 'it',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: "LW Sales Units",
            accessor: "lw_qty",
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: "WTD Sales Units",
            accessor: "tw_qty",
            Cell: (instance) => (
                <div className={colorCodeRow(instance?.row?.original,props.sizes) ? `highlight_row` : ""}>
                    {instance?.row?.original.tw_qty !== null ? instance?.row?.original.tw_qty: "-"}   
                </div>
            ),
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: 'Max Constraint',
            accessor: 'max',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: 'Min Constraint',
            accessor: 'min',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),

        },
        ...hideColumnsIfFinalize(),
        {
            Header: 'Allocated Quantity',
            id: 'allocated_split',
            columns: [
                {
                    Header: " ",
                    columns: [{
                    Header: 'Pack ID',
                    accessor: 'packs',
                    Filter: ColumnFilter,
                    Cell: (instance) => cellRendererForRows(instance),
                    }]
                },
                {
                    Header: '# Packs Allocated',
                    id: "packs_split",
                },
                {
                    Header: '# Eaches Allocated',
                    id: "eaches_split",
                },
                {
                    Header: " ",
                    columns: [{
                    Header: 'Total Allocated Units',
                    accessor: row => Object.values(row.allocated_quantity).reduce((preSum, curr) => preSum+curr, 0),
                    Cell: (inst) => (
                        <div className={colorCodeRow(inst?.row?.original,props.sizes) ? `highlight_row` : ""}>
                          {numberUSFormatting(inst?.value)}
                        </div>),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    width: 200
                    }]
                },
            ]
        },
        {
            Header: 'Min Allocation',
            accessor: 'allocated_for_min',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        {
            Header: 'WOS Allocation',
            accessor: 'allocated_for_wos',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),
        },
        // {
        //     Header: 'DC Name',
        //     accessor: 'dc',
        //     Filter: ColumnFilter,
        //     filter: 'exactText',
        // },
        {

            Header: "Net DC Available",
            accessor: "dc_avai_art",
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {


            Header: "Size Integrity in %",
            accessor: "size_integrity",
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: (instance) => cellRendererForRows(instance),


        },
        // {
        //     Header: "Allocated Quantity",
        //     columns: []
        // },

    ], [selectedRows,is_pack,props.isFinalized])

    const editAllocateQty = (p_instance) => {
        let { selectedRow } = props
        let req = {
            "allocationcode": props.allocationcode,
            "article": selectedRow.article,
            "store_code": p_instance.store_code
        }
        if (props.viewAllocationCall) {
            req["page"] = "viewPastAllocation"
        }
        if (props.releasedDate) {
            req["released_date"] = props.releasedDate
        }
        setEditData(req)
        props.getPackDetails(req);
        setShowEditModal(true)
    }

    const closeEditModal = (callAllApi) => {
        setShowEditModal(false)
        if (callAllApi) {
            props.callAllApis()
        }
    }

    const onBulkEdit = () => {
        if(isEmpty(Object.keys(RTinstance.state.selectedRowIds))) {
            Notify.error("Please select atleast 1 store to edit!!")
            return;
        }
        let selectedIds = RTinstance?.getSelectedRows
        let data = RTinstance.data.filter((val,ind) => selectedIds.includes(`${ind}`))
        let dcs = data?.map(val => val?.dc_code)
        let dcsLenght = dcs?.length ? [...new Set(dcs)] : 0
        // if(+dcsLenght?.length === 1) {
            setSelectedRows(Object.keys(RTinstance.state.selectedRowIds))
            setButtonClicked(true);
        // }
        // else {
        //     Notify.error("Please select the stores mapped with same dc!!")
        // }
    }

    const resetButtonClick = () => {
        setButtonClicked(false)
        setTimeout(() => {
            scrollIntoView(pdtRef)
        }, 200)
    }

    const onEdit = () => {

        if (isEmpty(Object.keys(RTinstance.state.selectedRowIds))) {
            Notify.error("Please select atleast 1 store to edit!!")
            showAvailable && setShowAvailable(false)
            return;
        }
        let columnData = [{
            Header: "Dc Name",
            accessor: "dc_name",
            width: 200
        }]
        let sizes = props.sizes

        let column = [{
            Header: "Eaches Available",
            columns: sizes.map(val => {
            return {
                Header: val,
                accessor: (data) => data?.size?.[val]
            }
        })}]
        let data = [];

        let keys; 
        let max_eaches_available = cloneDeep(props?.productDetails?.eaches_available) || {};
        let max_pack_available = cloneDeep(props?.productDetails?.packs_available) || {};
        if(is_eaches) {
            keys = Object.keys(max_eaches_available);
        }
        else {
            keys = Object.keys(max_pack_available);
        }

        keys.forEach((dc, index) => {
  
            let pushObj = {
              dc_name: dc,
            };
            if(is_eaches) {
              pushObj = {
                ...pushObj,
                size: max_eaches_available?.[dc],
              }
            }
            
            if (is_pack) {
                pushObj = {
                    ...pushObj,
                    packs_available: max_pack_available?.[dc],
                };
                if(index === keys?.length - 1) {
                //   let packConfig = Object.values(pack_config)[0];
                  data.push(pushObj)
                  pushObj = {
                    dc_name: "Pack Config",
                    size: pack_config,
                  }
                }
            }
            data.push(pushObj);
        });

        if (is_eaches) {
            setIsEdit(true)
            setInitIndex(pageIndex)
            let max_eaches_available_data = cloneDeep(props?.productDetails?.eaches_available) || {};
            let selectRows = {}
            Object.keys(RTinstance.state.selectedRowIds).forEach((rowId) => {
                selectRows[rowId] = true
                sizes.forEach((s, index) => {
                    max_eaches_available_data[s] = (Number(max_eaches_available_data[s]) || 0) + (Number(props.data?.[rowId]?.["eaches_size_value"]?.[index]) || 0)
                })
            });
            setInitialSelectedRows(selectRows)
            let keys = Object.keys(max_eaches_available)
            let dc_map = props?.productDetails?.dc_map
            // data = keys.map(val => {
            //     return {
            //         dc_name: val,
            //         size: max_eaches_available?.[val],
            //     }
            // })
            setEachesAvailable(max_eaches_available)
            setUpdatesEachesSizeMapping({})

            // let SizeColumn = sizes.map(val => {
            //     return {
            //         Header: val,
            //         accessor: (data) => data?.size[val]
            //     }
            // })

            // let table_data = cloneDeep(props.data)
     
            // let dcMapping = {}
            // dcs.forEach(item => {
            //     let dcKey = Object.keys(item)[0]
            //     dcMapping[dcKey] = Object.values(item)[0]
            // })
            // table_data.forEach(item => {
            //     for(let dc in dcMapping) {
            //         if(Object.keys(item.eaches_size_value).includes(dc)) {
            //             props.sizes.forEach((s, index) => {
            //                 item[dcMapping[dc]+s] = item.eaches_size_value[dc][index]
            //             })
            //         }
            //     }
            // })
            
        
            // setTableData(table_data)

            // columnData = [...columnData, ...SizeColumn]
            // setAvailableColumns(columnData)
            // setAvailableData(data)
        }

        if (is_pack) {
            let keys = Object.keys(max_pack_available)
            // data = data.concat(keys.map(val => {
            //     return {
            //         ...pack_config,
            //         dc_name: val,
            //         packs_available: max_pack_available?.[val]
            //     }
            // }));

            setPacksAvailable(max_pack_available)
            
            column.push({
                Header: '# Packs Avaialble',
                accessor: 'packs_available'
            })
            // setAvailableColumns([...columnData,...column])
            // setAvailableData(data)
        }
        setAvailableColumns([...columnData,...column])
        setAvailableData(data)

        setPacksRequest({})
        setEachesRequest({})
        setShowAvailable(true)
        setSelectedRows(Object.keys(RTinstance.state.selectedRowIds))
    }

    const onSave = () => {
        if(!RTinstance.selectedFlatRows?.length) {
            Notify.error("Please Select atleast one row!!")
            return;
        }
        else if (isEmpty(packsRequest) && isEmpty(eachesRequest)) {
            Notify.error("Please Edit before saving!!")
            return;
        }

        let stores_details = {}
        let l_pack_id
        let l_available_eaches = {}
        const {article} = props.selectedRow
        const {allocationcode} = props   
        let request = {
            "allocation_code": allocationcode,
            "article": article
        }
        
        if(is_pack && !isEmpty(packsRequest)) {
            Object.keys(packsRequest).forEach(val => {
                let updated_packs = {}
                for(let dc in packsRequest[val]) {
                    l_pack_id = packsRequest[val][dc].split("+-+-")[1]
                    // for(let pack in packsRequest[val][dc]) {
                        updated_packs[dc] = {[packsRequest[val][dc].split("+-+-")[1]]: packsRequest[val][dc].split("+-+-")[0]}
                    // }
                }
                stores_details[val] = {
                    "store": val,
                    "updated_packs": updated_packs
                }
            })
           
            if(availableData?.some(val => val?.packs_available < 0)) {
                Notify.error("The allocated packs are more than the available units!!")
                return;
            }

            let l_available_packs = {}
            availableData.forEach(val => {
                if(val?.dc_name !== "Pack Config") {
                    l_available_packs = {
                        ...l_available_packs,
                        [val?.dc_name]: {
                            [l_pack_id]: val["packs_available"]
                        }
                    }
                }
            })

            request = {
                ...request,
                "available_packs": {
                    ...l_available_packs
                }
            }
        }

        if (is_eaches && !isEmpty(eachesRequest)) {
            let l_sizes = availableData?.map(val => val.size)
            for (let i = 0; i < l_sizes.length; i++) {
                if (Object.values(l_sizes[i]).some(val => val < 0)) {
                    Notify.error(`The allocated eaches for one of the sizes are more than the available units!!`)
                    return;
                }
            }
            // for(let i in availableData) {
            //     if(Object.values(availableData[i].size) < 0) {
            //         Notify.error(`The allocated eaches are more than the available units for size ${i}!!`) 
            //         return;
            //     } 
            // }

            Object.keys(eachesRequest).forEach(val => {
                if(stores_details[val]) {
                    stores_details[val] = {...stores_details[val], "updated_eaches": eachesRequest[val]};
                }
                else {
                    stores_details[val] = {
                        "store": val,
                        "updated_eaches": eachesRequest[val]
                    }
                }
            })

            for (let i in updatesEachesSizeMapping) {
                l_available_eaches[i] = (Number(eachesAvailable[i]) || 0) - (Number(updatesEachesSizeMapping[i]) || 0)
            }
            // let old_eaches_available = cloneDeep(props?.productDetails?.eaches_available) || {};
            let available_eaches = {}
            availableData.forEach(val => {
                if(val?.dc_name !== "Pack Config") {
                    available_eaches = {
                        ...available_eaches,
                        [val?.dc_name]: val?.size
                    }
                }
            })

            request = {
                ...request,
                "available_eaches": available_eaches,
                "stores": Object.values(stores_details)
            }

            // RTinstance?.selectedFlatRows.forEach((store, stIndex) => {
            //     for(let dc in eachesAvailable) {
            //         if(request.stores[stIndex]) {
            //             if(!request.stores[stIndex]["updated_eaches"].hasOwnProperty(dc) && Object.keys(store.original.dc_available_size_final).includes(dc)){
            //                 console.log("updated_eaches", dc, store.original.store_code)
            //                 request.stores[stIndex]["updated_eaches"][dc] = {}
            //                 props.sizes.forEach((s, index) => {
            //                     request.stores[stIndex]["updated_eaches"][dc][s] = store.original?.size_value?.[dc]?.[index] || 0
            //                 })
            //             }
            //         }
            //     }
            // })


            //To take all the available DC for each store

            request.stores.forEach((detail) => {
                for(let dc in eachesAvailable) {
                    let storeRow = RTinstance?.selectedFlatRows.filter(store => store.original.store_code == detail.store)[0]?.original

                    if(eachesRequest?.[storeRow?.store_code]) {
                        if(!detail["updated_eaches"].hasOwnProperty(dc) && Object.keys(storeRow?.dc_available_size_final).includes(dc)){
                            detail["updated_eaches"][dc] = {}
                            props.sizes.forEach((s, index) => {
                                detail["updated_eaches"][dc][s] = storeRow?.size_value?.[dc]?.[index]
                            })
                        }
                    }
                }
            })

        }
        let { originalAllocationCode } = props
        let l_original_allocation_code = allocationcode === originalAllocationCode ? "" : originalAllocationCode
        request["original_allocation_code"] = l_original_allocation_code
        
        if(props?.articles?.length) {
            request["articles"] = props?.articles
        }
        if(!request?.stores) {
            request["stores"] = Object.values(stores_details);
        }
        if(request?.stores?.length) {
            setTimeout(() => {
                scrollIntoView(pdtRef)
            }, 200)
            props.updatePacksEachesForMultipleStores(request)
        }
        else {
            Notify.error("No changes to save!!")
            return;
        }
    }

    useEffect(() => {
        if (props.updatePackEachesError) {
            setPacksRequest({})
            setEachesRequest({})
            setSelectedRows([])
            Notify.error("Error in Editing Allocated Units")
        }
    }, [props.updatePackEachesError])


    useEffect(() => {
        if (props.updatePackEachesData) {
            setPacksRequest({})
            setEachesRequest({})
            setSelectedRows([])
            Notify.success("Allocated Units Edited Sucessfully!!")
            props.callAllApis()
        }
    }, [props.updatePackEachesData])

    const setSizeColumns = useCallback(() => {
        const getEachesDCValue = ((row, dc) => {
            let value = row?.allocated_quantity[dc] - (row?.packs_allocated?.[dc] || 0)
            return isNaN(value) ? "" : value
        })
        if (props.sizes.length) {
            // let dcNames = Object.keys(props?.productDetails?.eaches_available)
            // let dcNames = Object.values(dcs)
            // let dcNames = ["CA7777-CAN-Outlet-Store", "JAX-USA-Outlet-Store"]
            let dcAvailableIndex = productDetailsDatacolumns.findIndex(col => col.accessor === "dc_avai_art")
            let allocatedIndex = productDetailsDatacolumns.findIndex(col => col.id === "allocated_split")
            productDetailsDatacolumns[allocatedIndex].columns[2].columns = dcs?.map(dc => {      
                return {
                    Header: Object.values(dc)[0],
                    id: Object.keys(dc)[0]+"eaches",
                    accessor: row => row.loose_units_allocated?.[Object.keys(dc)[0]],
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    width: 150,
                    Cell: (instance) => cellRendererForRows(instance),
                }
            })
            productDetailsDatacolumns[allocatedIndex].columns[1].columns = dcs?.map(dc => {
                let dcKey = Object.keys(dc)[0]
                return {
                    Header: Object.values(dc)[0],
                    id: dcKey+"packs",
                    accessor: row => row?.[dcKey+"packs"] || row.packs_allocated[dcKey],
                    Cell: (instance) => (
                        is_pack && selectedRows.includes(instance.row.id) && Object.keys(instance?.row?.original?.packs_allocated).includes(dcKey) && !buttonClicked ?
                            <div className={colorCodeRow(instance?.row?.original,props.sizes) ? `highlight_row` : ""}>
                                <InputCell {...instance} style={{ textAlign: "center" }} min={0} type="number" step={1}
                                    blurHandler={(rowIdx, columnId, values, row_id, temp_id, row, focusedValue) => {
                                        let modified = []
                                        availableData.forEach(val => {
                                            if (val.dc_name === dcKey) {
                                                val['packs_available'] = (Number(val['packs_available']) || 0) - (Number(values) - Number(focusedValue))
                                                modified.push(val)
                                            }
                                            else {
                                                val['packs_available'] = val['packs_available']
                                                modified.push(val)
                                            }

                                        })
                                        setAvailableData(modified)
                                    }}
                                    changeHandler={(rowIdx, columnId, values) => {
                                        setPacksRequest((old) => {
                                            return {
                                                ...old,
                                                [instance.row.original['store_code']]: { ...old[instance.row.original['store_code']], [Object.keys(dc)[0]]: `${values}+-+-${instance.row.original['packs']}` },
                                            }
                                        })
                                    }}
                                />
                            </div> :
                            <div className={colorCodeRow(instance?.row?.original,props.sizes) ? `highlight_row` : ""}>
                                {instance.row.original?.packs_allocated[dcKey]}
                            </div>
                    ),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    width: 150
                }
            })
            productDetailsDatacolumns[dcAvailableIndex].columns = dcs?.map(dc => {
                return {
                    Header: Object.values(dc)[0],
                    id: Object.keys(dc)[0],
                    accessor: row => row.dc_available_size_final[Object.keys(dc)[0]],
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    width: 200,
                    Cell: (instance) => cellRendererForRows(instance)
                }
            })
            const getTotalAllocatedBysize = (dcValues, index) => {
                let sum = 0;
                Object.values(dcValues).forEach(item => sum += Number(item[index]))
                return sum;
            }
            props.sizes.forEach((s, index) => {
                productDetailsDatacolumns.push({
                    Header: s,
                    columns: [
                        {
                            Header: 'Eaches Allocated',
                            id: `Eaches Allocated ${s} `,
                            columns: dcs?.map(dc => {
                                let dcKey = Object.keys(dc)[0]
                                let dcValue = Object.values(dc)[0]
                                return {
                                Header: dcValue,
                                id: dcValue+s,
                                accessor: row => row?.[dcValue+s] || row.eaches_size_value?.[dcKey]?.[index],
                                Cell: (instance) => (
                                    is_eaches && selectedRows.includes(instance.row.id) && Object.keys(instance?.row?.original?.dc_available_size_final).includes(dcKey) && !buttonClicked ? 
                                    <div className={colorCodeRow(instance?.row?.original,props.sizes) ? `highlight_row` : ""}>
                                        <InputCell {...instance} style={{ textAlign: "center" }} min={0} step={1} type="number"
                                        blurHandler={(rowIdx, columnId, values, row_id, temp_id, row, focusedValue) => {
                                            setUpdatesEachesSizeMapping((old) => {
                                                return {
                                                    ...old,
                                                    dc: {[s]: (Number(old?.[dcKey]?.[s]) || 0) + Number(values) - Number(focusedValue)}
                                                }
                                            })
                                            let modified = []
                                            availableData.forEach(val => {
                                                if (val.dc_name === dcKey) {
    
                                                    val.size[s] = (Number(val?.size[s])) - (Number(values) - Number(focusedValue))
                                                    modified.push(val)
                                                }
                                                else {
                                                    val.size[s] = val.size[s]
                                                    modified.push(val)
                                                }
    
                                            })
                                            setAvailableData(modified)
                                            // return [{
                                            //     [s]:  (Number(old[0][s]) || 0) - (Number(values) - Number(focusedValue))
                                            // }]
                                            // })
                                        }}
                                        changeHandler={(rowIdx, columnId, values) => {
                                            setEachesRequest((old) => {
                                                return {
                                                    ...old,
                                                    [instance.row.original['store_code']]: {
                                                        ...old?.[instance.row.original['store_code']],
                                                        [dcKey]: {...old?.[instance.row.original['store_code']]?.[dcKey], [s]: values}
                                                    },
                                                }
                                            })
                                        }}
                                    /> 
                                    </div>:
                                        <div className={colorCodeRow(instance?.row?.original,props.sizes) ? `highlight_row` : ""}> {instance.row.original.eaches_size_value[dcKey]?.[index] ? Number(instance.row.original.eaches_size_value[dcKey]?.[index]).toFixed(0) : 0} </div>
                                ),
                                Filter: NumberRangeColumnFilter,
                                filter: 'between',
                                width: 200,
                            }}),
                            // accessor: row => row.size_value[index] ? Number(row.size_value[index]).toFixed(0) : 0,

                        },
                        {
                            Header: 'Allocated Quantity',
                            id: `Allocated Quantity ${s} `,
                            columns: dcs?.map(dc => {
                                let dcKey = Object.keys(dc)[0]
                                let dcValue = Object.values(dc)[0]
                                return {
                                Header: dcValue,
                                id: "allocated"+dcValue+s,
                                accessor: row => row?.["allocated"+dcValue+s] || row.size_value?.[dcKey]?.[index],
                                // Cell: (instance) => (
                                //     is_eaches && selectedRows.includes(instance.row.id) && Object.keys(instance?.row?.original?.dc_available_size_final).includes(dcKey) && !buttonClicked ? <InputCell {...instance} style={{ textAlign: "center" }} min={0} step={1} type="number"
                                //         blurHandler={(rowIdx, columnId, values, row_id, temp_id, row, focusedValue) => {
                                //             setUpdatesEachesSizeMapping((old) => {
                                //                 return {
                                //                     ...old,
                                //                     dc: {[s]: (Number(old?.[dcKey]?.[s]) || 0) + Number(values) - Number(focusedValue)}
                                //                 }
                                //             })
                                //             let modified = []
                                //             availableData.forEach(val => {
                                //                 if (val.dc_name === dcKey) {
    
                                //                     val.size[s] = (Number(val?.size[s])) - (Number(values) - Number(focusedValue))
                                //                     modified.push(val)
                                //                 }
                                //                 else {
                                //                     val.size[s] = val.size[s]
                                //                     modified.push(val)
                                //                 }
    
                                //             })
                                //             setAvailableData(modified)
                                //             // return [{
                                //             //     [s]:  (Number(old[0][s]) || 0) - (Number(values) - Number(focusedValue))
                                //             // }]
                                //             // })
                                //         }}
                                //         changeHandler={(rowIdx, columnId, values) => {
                                //             setRequest1((old) => {
                                //                 return {
                                //                     ...old,
                                //                     [instance.row.original['store_code']]: {
                                //                         ...old?.[instance.row.original['store_code']],
                                //                         [dcKey]: {...old?.[instance.row.original['store_code']]?.[dcKey], [s]: values}
                                //                     },
                                //                 }
                                //             })
                                //         }}
                                //     /> :
                                //         <div> {instance.row.original.size_value[dcKey]?.[index] ? Number(instance.row.original.size_value[dcKey]?.[index]).toFixed(0) : 0} </div>
                                // ),
                                Filter: NumberRangeColumnFilter,
                                filter: 'between',
                                width: 200,
                                Cell: (instance) => cellRendererForRows(instance)

                            }}),
                            // accessor: row => row.size_value[index] ? Number(row.size_value[index]).toFixed(0) : 0,

                        },
                        {
                            Header: '  ',
                            columns:[{
                            Header: 'OH+OO+IT',
                            id: `OH+OO+IT ${s}`,
                            accessor: row => Number(row.it_value[index]) + Number(row.oh_value[index]) + Number(row.oo_value[index]),
                            Cell: (instance) => (
                                <div className={colorCodeRow(instance?.row?.original,props.sizes) ? `highlight_row` : ""}>
                                    {(instance.row.original.it_value[index] === undefined && instance.row.original.oh_value[index] === undefined && instance.row.original.oo_value[index] === undefined ) ?  "-": Number(instance.row.original.it_value[index]) + Number(instance.row.original.oh_value[index]) + Number(instance.row.original.oo_value[index])}  
                                </div>
                            ),
                            Filter: NumberRangeColumnFilter,
                            filter: 'between'
                            }]
                        },
                        {
                            Header: ' ',
                            columns: [{
                            Header: 'Projected Total',
                            id: `Projected Total ${s}`,
                            accessor: row => getTotalAllocatedBysize(row.size_value, index) + Number(row.it_value[index]) + Number(row.oh_value[index]) + Number(row.oo_value[index]),
                            Filter: NumberRangeColumnFilter,
                            filter: 'between',
                            Cell: (instance) => cellRendererForRows(instance)
                            }]
                        },
                        {
                            Header: ' ',
                            columns:[{
                            Header: 'LW Sales Units',
                            id: `LW ${s}`,
                            accessor: row => Number(row?.lw_qty_value?.[index]),
                            Cell: (instance) => (
                                <div className={colorCodeRow(instance?.row?.original,props.sizes) ? `highlight_row` : ""}>
                                    {instance.row.original.lw_qty_value[index] === undefined ?  "-": Number(instance.row.original.lw_qty_value?.[index])}   
                                </div>
                            ),
                            Filter: NumberRangeColumnFilter,
                            filter: 'between',
                            }]
                        },
                        {
                            Header: ' ',
                            columns:[{
                            Header: 'WTD Sales Units',
                            id: `WTD ${s}`,
                            accessor: row => Number(row?.tw_qty_value?.[index]),
                            Cell: (instance) => (
                                <div className={colorCodeRow(instance?.row?.original,props.sizes) ? `highlight_row` : ""}>
                                    {instance.row.original.tw_qty_value[index] === undefined ?  "-": Number(instance.row.original.tw_qty_value?.[index])}   
                                </div>
                            ),
                            Filter: NumberRangeColumnFilter,
                            filter: 'between',
                            }]
                        }
                    ],
                })
            })
            productDetailsDatacolumns.push({
                Header: " ",
                sticky: "right",
                columns:[{
                Header: " ",
                sticky: "right",
                columns: [
                    {
                        Header: "Actions",
                        accessor: "actions",
                        Cell: (instance) => (
                            <div className={`${props.isFinalized ? "disable__column" : ""} ${colorCodeRow(instance?.row?.original,props.sizes) ? `highlight_row` : ""}`}>
                                <EditIconCell
                                    handleEdit={editAllocateQty}
                                    instance={instance}
                                ></EditIconCell>
                            </div>
                        ),
                        width: 100,
                        resizable: true,
                        disableFilters: true,
                    }
                ]
            }]

            })
            // setTableColumns(columns)
            return true
        }
        return false
    }, [selectedRows, is_eaches, props.sizes, props.isFinalized])

    const getPageIndex = (page) => {
        if(!isEdit) {
            setPageIndex(page)
        }
        else {
            setIsEdit(false)
        }
    }

    const colorCodeRow = (row, sizes) => {
        const { 
            oh, 
            oo, 
            it, 
            min, 
            allocated_quantity, 
            oh_value, 
            oo_value, 
            it_value, 
            size_value,
            min_size, 
        } = row;
            
        // Calculate total allocated quantity
        const totalAllocatedQuantity = Object.values(allocated_quantity)
            ?.reduce((preSum, curr) => preSum + curr, 0);
    
        // Calculate size level allocation values
        const sizeLevelAllocationValue = Object.values(size_value);
    
        // Check if total allocation is less than minimum
        const isTotalAllocationBelowMin = Number(oh) + Number(oo) + Number(it) + Number(totalAllocatedQuantity) < Number(min);
    
        if (!props.viewAllocationCall) {
            // Check total allocation below overall level min OR size-level allocation below min
            if (isTotalAllocationBelowMin || 
                (sizes?.length && sizes.some((s, index) => 
                    Number(oh_value[index]) + 
                    Number(oo_value[index]) + 
                    Number(it_value[index]) + 
                    Number(sizeLevelAllocationValue[index]) < 
                    Number(min_size?.[index])
                ))
            ) {
                return true;
            }
        }
    
        return false;
    };
    
      const cellRendererForRows = (instance) => {
        return (
          <div className={ colorCodeRow(instance?.row?.original,props.sizes) ? `highlight_row`:""}>
            {instance?.value}
          </div>
        )
      };

    return (
      keyRT &&
      setSizeColumns() && (
        <>
          {/* {
                !showEditModal &&
                 <Notification /> 
            } */}
          {selectedRows.length > 0 &&
            buttonClicked &&
            (is_eaches && is_pack ? (
              <PacksEachesBulkEdit
                selectedFlatRows={RTinstance?.selectedFlatRows}
                buttonClicked={buttonClicked}
                resetButtonClick={resetButtonClick}
                packConfig={pack_config}
                sizes={props.sizes}
                // buttonLabel={buttonLabel}
                packsAvailable={props?.productDetails?.packs_available}
                eachesAvailable={props?.productDetails?.eaches_available}
                article={props.selectedRow.article}
                allocationCode={props.selectedRow.allocationcode}
                allocationCodeAfterUpdate={props.allocationcode}
                originalAllocationCode={props.originalAllocationCode}
                articles={props?.articles}
              />
            ) : (
              <BulkEditPacksAndEaches
                selectedFlatRows={RTinstance?.selectedFlatRows}
                buttonClicked={buttonClicked}
                resetButtonClick={resetButtonClick}
                packConfig={pack_config}
                sizes={props.sizes}
                buttonLabel={buttonLabel}
                packsAvailable={props?.productDetails?.packs_available}
                eachesAvailable={props?.productDetails?.eaches_available}
                article={props.selectedRow.article}
                allocationCode={props.selectedRow.allocationcode}
                allocationCodeAfterUpdate={props.allocationcode}
                originalAllocationCode={props.originalAllocationCode}
                articles={props?.articles}
              />
            ))}

          {showAvailable && (
            <AvailableTable
              columns={availableColumns}
              data={availableData}
              buttonLabel={buttonLabel}
              is_pack={is_pack}
              is_eaches={is_eaches}
            />
          )}

          <div className="storesStore__heading" ref={pdtRef}>
            <h1 className="fnt-md fnt-bold">{props.selectedRow.article}</h1>
            {props.data.length > 1 && (
              <h1 className="fnt-md fnt-bold">Stores</h1>
            )}
          </div>
          <PageLoader loader={props.updatePackEachesLoading} gridLoader={true}>
            <ReactTableWithPlugins
              hideOptions={props.sizes}
              shouldPagination
              tableWrapperStyle={{ height: "fit-content", maxHeight: "60rem" }}
              headerWrapperStyle={{ position: "sticky", top: 0, zIndex: "4" }}
              // embededScroll
              totalRecordsLen={props.data ? props.data.length : 0}
              data={tableData}
              columns={productDetailsDatacolumns}
              initialSelectedRows={initSelectedRows}
              sortBy={[{ id: "store_code", desc: false }]}
              renderMarkup="TableMarkup"
              prepareRequest={true}
              setIsDisabled={setIsDisabled}
              getInstanceOnMount={(instance) => {
                setRTinstance(instance);
              }}
              keyRT={keyRT}
              features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
              initialHiddenColumns={initialHiddenColumns}
              tableId="finalize_product_details"
              pageSize={getNumberOfRecordsPerPages(
                "finalize_product_view",
                props.data.length
              )}
              pagesList={[10, 20, 30]}
              getPageIndex={(index) => getPageIndex(index)}
              initialPageIndex={initIndex}
            />
            {!props.viewAllocationCall &&<Indicator className="custom-indicator__styles" text = "Store Inventory + Allocated qty is less than the Min Constraint" color = "lightgrey" />}
            {!props.allocationStatus &&
              !isSizeComponentForEdit() &&
              showButtons &&
              !is_multiple_packs &&
              !props.viewAllocationCall &&
              !props.isFinalized &&
              props.showEditButtons && (
                <div style={{ textAlign: "center", margin: "2rem" }}>
                  <button
                    style={{ margin: "2.5rem 1.5rem 1.5rem" }}
                    onClick={onBulkEdit}
                    className="btn btn-primary"
                    // disabled={isDisabled}
                  >
                    Bulk Edit {buttonLabel}
                  </button>
                  {
                    //   !(is_pack && is_eaches) &&
                    <button
                      style={{ margin: "2.5rem 1.5rem 1.5rem" }}
                      onClick={onEdit}
                      className="btn btn-primary"
                      // disabled={isDisabled}
                    >
                      Edit {buttonLabel}
                    </button>
                  }
                  <button
                    style={{ margin: "2.5rem 1.5rem 1.5rem" }}
                    onClick={onSave}
                    className="btn btn-primary"
                    // disabled={isDisabled}
                  >
                    Save
                  </button>
                </div>
              )}
          </PageLoader>

          {showEditModal ? (
            isSizeComponentForEdit() ? (
              <EditProductAllocationModal
                fields={productDetailsDatacolumns[5].columns}
                showAlert={true}
                closeAlert={closeEditModal}
                data={editData}
                articles={props?.articles}
              />
            ) : (
              <EditPackDetails
                showAlert={true}
                closeAlert={closeEditModal}
                data={editData}
                originalAllocationCode={props.originalAllocationCode}
                articles={props?.articles}
                details={{ is_pack, is_eaches }}
              />
            )
          ) : null}
        </>
      )
    );

}


const mapStateToProps = ({ finalize }) => {
    return {
        updatePackEachesLoading: finalize.updatePackEachesLoading,
        updatePackEachesData: finalize.updatePackEachesData,
        updatePackEachesError: finalize.updatePackEachesError,
        allocationStatus: finalize.allocationStatus
    }
}

const mapDispatchToProps = (dispatch) => ({
    getPackDetails: (payload) => dispatch(getPackDetails(payload)),
    updatePacksEachesForMultipleStores: (payload) => dispatch(updatePacksEachesForMultipleStores(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsTable)